.MuiButtonGroup-lastButton:hover{
    color: #EF6C8F!important;
}

.MuiButtonGroup-middleButton:hover{
    color: #fd9602!important;
}

.MuiButtonGroup-firstButton:hover{
    color: #2789E3!important;
}

